import { createSlice } from '@reduxjs/toolkit';

const reportDataSlice = createSlice({
  name: 'reportData',
  initialState: { reportData: {} },
  reducers: {
    updateReportData(
      state,
      action: { payload: { reportId: number; data: { [key: string]: unknown }[] } },
    ) {
      state.reportData = {
        id: action.payload.reportId,
        data: action.payload.data,
      };
    },
  },
});

export const { updateReportData } = reportDataSlice.actions;
export default reportDataSlice.reducer;
